export const environment = {
  production: false,
  hmr: false,
  argoHostLive: 'api.ml3ds-rt-cloud.com',
  argoHostStage: 'argo.ml3ds-rt-stage.com',
  iconHostLive: 'ml3ds-rt-icon.com',
  iconHostStage: 'ml3ds-rt-iconstage.com',
  krakenHostLive: 'ml3ds-rt-cloud.com',
  krakenHostStage: 'ml3ds-rt-stage.com',
  productDataDomain: 'https://productdatart.blob.core.windows.net',
  iconBaseDomain: 'https://secure.ml3ds-rt-iconstage.com',
  offlinePackager: 'https://offlinepackager-rt.medialabinc.com',
  rpmDomain: 'http://rpm-sandbox',
  rpmApi: 'https://apicore.ml3ds-rt-cloud.com',
  buildNumber: '782',
  // TODO - change back when https is working on the dev site - http://medialabmaterialsdev.medialabinc.local/index.html#/
  colorSchemeDesignerAppUrl: 'https://medialabmaterials.medialabinc.com/index.html#/',
  internalIntegrationsApi: 'https://ii-test.medialabinc.com'
};
